<template>
  <div>
    <form @submit.prevent="save" ref="saveLancamento">
      <b-tabs
        justified
        nav-class="nav-tabs-custom"
        content-class="p-3 text-muted"
      >
        <b-tab active>
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block">Dados</span>
          </template>
          <div class="form-row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>Titulo: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': $v.lancamento.titulo.$error }"
                  placeholder="Descrição do lancamento"
                  v-model="$v.lancamento.titulo.$model"
                />
                <span
                  v-if="!$v.lancamento.titulo.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>Vencimento: <span class="text-danger">*</span></label>
                  <input
                    type="date"
                    class="form-control"
                    placeholder="Date de vencimento"
                    :class="{
                      'is-invalid': $v.lancamento.data_vencimento.$error,
                    }"
                    v-model="$v.lancamento.data_vencimento.$model"
                  />
                  <span
                    v-if="!$v.lancamento.data_vencimento.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </span>
                </div>
                <div class="form-group col-sm-6">
                  <label>Competência: <span class="text-danger">*</span></label>
                  <input
                    type="date"
                    class="form-control"
                    placeholder="Date da competencia"
                    :class="{ 'is-invalid': $v.lancamento.competencia.$error }"
                    v-model="$v.lancamento.competencia.$model"
                  />
                  <span
                    v-if="!$v.lancamento.competencia.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </span>
                </div>
              </div>
              <div class="form-row">
                <div
                  v-if="lancamento.tipo == 3 || lancamento.tipo == 4"
                  class="form-group col-sm-6"
                >
                  <label
                    >Data do pagamento:
                    <span class="text-danger">*</span></label
                  >
                  <input
                    type="date"
                    class="form-control"
                    placeholder="Date de pagamento"
                    v-model="lancamento.data_pagamento"
                  />
                </div>
              </div>
              <div class="form-group">
                <p>Tipo: <span class="text-danger">*</span></p>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="contaAReceber"
                    value="1"
                    v-model="lancamento.tipo"
                  />
                  <label class="form-check-label" for="contaAReceber">
                    A Receber
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="contaAPagar"
                    value="2"
                    v-model="lancamento.tipo"
                  />
                  <label class="form-check-label" for="contaAPagar">
                    A Pagar
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="entrada"
                    value="3"
                    v-model="lancamento.tipo"
                  />
                  <label class="form-check-label" for="entrada">
                    Entrada
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="saida"
                    value="4"
                    v-model="lancamento.tipo"
                  />
                  <label class="form-check-label" for="saida"> Saída </label>
                </div>
              </div>
              <div class="form-group">
                <label>Subcategoria: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{
                    'is-invalid': $v.lancamento.subcategoria_contas_id.$error,
                  }"
                  v-model="$v.lancamento.subcategoria_contas_id.$model"
                >
                  <option value="">Selecione uma subcategoria</option>
                  <option
                    v-for="sub in subcategorias"
                    :key="sub.id"
                    :value="sub.id"
                    :title="`${sub.descricao}`"
                  >
                    {{ sub.descricao }}
                  </option>
                </select>
                <span
                  v-if="!$v.lancamento.subcategoria_contas_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group">
                <label>Conta: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.lancamento.conta_id.$error }"
                  v-model="$v.lancamento.conta_id.$model"
                >
                  <option value="">Selecione uma conta</option>
                  <option
                    v-for="conta in contas"
                    :key="conta.id"
                    :value="conta.id"
                    :title="`${conta.descricao}`"
                  >
                    {{ conta.descricao }}
                  </option>
                </select>
                <span
                  v-if="!$v.lancamento.conta_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group">
                <label>Filial: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.lancamento.unidade_id.$error }"
                  v-model="$v.lancamento.unidade_id.$model"
                >
                  <option value="">Selecione uma filial</option>
                  <option
                    v-for="unidade in unidades"
                    :key="unidade.id"
                    :value="unidade.id"
                    :title="`${unidade.descricao}`"
                  >
                    {{ unidade.descricao }}
                  </option>
                </select>
                <span
                  v-if="!$v.lancamento.unidade_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group">
                <label>Observação:</label>
                <textarea
                  type="text"
                  class="form-control"
                  v-model="lancamento.observacao"
                />
              </div>
            </div>

            <!-- 2ª coluna -->
            <div class="col-sm-6">
              <div class="form-group">
                <p>Origem: <span class="text-danger">*</span></p>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="cliente"
                    value="1"
                    v-model="lancamento.origem"
                  />
                  <label class="form-check-label" for="cliente">
                    Cliente
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="fornecedor"
                    value="2"
                    v-model="lancamento.origem"
                  />
                  <label class="form-check-label" for="fornecedor">
                    Fornecedor
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="colaborador"
                    value="3"
                    v-model="lancamento.origem"
                  />
                  <label class="form-check-label" for="colaborador">
                    Colaborador
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="outro"
                    value="4"
                    v-model="lancamento.origem"
                  />
                  <label class="form-check-label" for="outro"> Outro </label>
                </div>
              </div>
              <div v-if="lancamento.origem == 1" class="form-group">
                <label>Cliente: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.lancamento.id_origem.$error }"
                  v-model="$v.lancamento.id_origem.$model"
                >
                  <option value="">Selecione um cliente</option>
                  <option
                    v-for="cliente in clientes"
                    :key="cliente.id"
                    :value="cliente.id"
                    :title="`${cliente.name}`"
                  >
                    {{ cliente.name }}
                  </option>
                </select>
                <span
                  v-if="!$v.lancamento.id_origem.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div v-if="lancamento.origem == 2" class="form-group">
                <label>Fornecedor: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.lancamento.id_origem.$error }"
                  v-model="$v.lancamento.id_origem.$model"
                >
                  <option value="">Selecione um fornecedor</option>
                  <option
                    v-for="fornecedor in fornecedores"
                    :key="fornecedor.id"
                    :value="fornecedor.id"
                    :title="`${fornecedor.name}`"
                  >
                    {{ fornecedor.name }}
                  </option>
                </select>
                <span
                  v-if="!$v.lancamento.id_origem.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div v-if="lancamento.origem == 3" class="form-group">
                <label>Colaborador:</label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.lancamento.id_origem.$error }"
                  v-model="$v.lancamento.id_origem.$model"
                >
                  <option value="">Selecione um colaborador</option>
                  <option
                    v-for="user in users"
                    :key="user.id"
                    :value="user.id"
                    :title="`${user.name}`"
                  >
                    {{ user.name }}
                  </option>
                </select>
                <span
                  v-if="!$v.lancamento.id_origem.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div v-if="lancamento.origem == 4" class="form-group">
                <label>Descrição Origem:</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Descrição da Origem"
                  :class="{ 'is-invalid': $v.lancamento.id_origem.$error }"
                  v-model="$v.lancamento.id_origem.$model"
                />
                <span
                  v-if="!$v.lancamento.id_origem.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group">
                <label>Valor: <span class="text-danger">*</span></label>
                <div class="input-group">
                  <span
                    class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                  >
                    <span class="input-group-text">R$</span></span
                  >
                  <input
                    type="number"
                    class="form-control"
                    step="0.01"
                    :class="{ 'is-invalid': $v.lancamento.valor_base.$error }"
                    v-model.number="$v.lancamento.valor_base.$model"
                    placeholder="000,00"
                    @blur="formatarValor(lancamento.valor_base, 'valor_base')"
                  />
                </div>
                <span
                  v-if="!$v.lancamento.valor_base.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div
                v-if="lancamento.tipo == 3 || lancamento.tipo == 4"
                class="form-row"
              >
                <div class="form-group col-sm-6">
                  <label>Juros: <span class="text-danger">*</span></label>
                  <div class="input-group">
                    <span
                      class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                    >
                      <span class="input-group-text">R$</span></span
                    >
                    <input
                      type="number"
                      class="form-control"
                      step="0.01"
                      v-model.number="lancamento.juros"
                      placeholder="000,00"
                      @blur="formatarValor(lancamento.juros, 'juros')"
                    />
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <label>Multa: <span class="text-danger">*</span></label>
                  <div class="input-group">
                    <span
                      class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                    >
                      <span class="input-group-text">R$</span></span
                    >
                    <input
                      type="number"
                      class="form-control"
                      step="0.01"
                      v-model.number="lancamento.multa"
                      placeholder="000,00"
                      @blur="formatarValor(lancamento.multa, 'multa')"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="lancamento.tipo == 3 || lancamento.tipo == 4"
                class="form-row"
              >
                <div class="form-group col-sm-6">
                  <label>ISSRF: <span class="text-danger">*</span></label>
                  <div class="input-group">
                    <span
                      class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                    >
                      <span class="input-group-text">R$</span></span
                    >
                    <input
                      type="number"
                      class="form-control"
                      step="0.01"
                      v-model.number="lancamento.issrf"
                      placeholder="000,00"
                      @blur="formatarValor(lancamento.issrf, 'issrf')"
                    />
                  </div>
                </div>
                <div class="form-group col-sm-6">
                  <label
                    >Outras retenções: <span class="text-danger">*</span></label
                  >
                  <div class="input-group">
                    <span
                      class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                    >
                      <span class="input-group-text">R$</span></span
                    >
                    <input
                      type="number"
                      class="form-control"
                      step="0.01"
                      v-model.number="lancamento.outras_retencoes"
                      placeholder="000,00"
                      @blur="
                        formatarValor(
                          lancamento.outras_retencoes,
                          'outras_retencoes',
                        )
                      "
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="lancamento.tipo == 3 || lancamento.tipo == 4"
                class="form-row"
              >
                <div class="form-group col-sm-6">
                  <label>Desconto: </label>
                  <div class="input-group">
                    <span
                      class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                    >
                      <span class="input-group-text">R$</span></span
                    >
                    <input
                      type="number"
                      class="form-control"
                      step="0.01"
                      v-model.number="lancamento.desconto"
                      placeholder="000,00"
                      @blur="formatarValor(lancamento.desconto, 'desconto')"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="lancamento.tipo == 3 || lancamento.tipo == 4"
                class="form-row"
              >
                <div class="form-group col-sm-6">
                  <label>Valor Total: </label>
                  <div class="input-group">
                    <span
                      class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                    >
                      <span class="input-group-text">R$</span></span
                    >
                    <input
                      type="number"
                      class="form-control"
                      step="0.01"
                      v-model.number="lancamento.valor_total"
                      placeholder="000,00"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label
                    >Forma de Pagamento:
                    <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-control"
                    :class="{
                      'is-invalid': $v.lancamento.forma_pagamento_id.$error,
                    }"
                    v-model="$v.lancamento.forma_pagamento_id.$model"
                  >
                    <option value="">Selecione uma forma de pagamento</option>
                    <option
                      v-for="forma in formasPagamentos"
                      :key="forma.id"
                      :value="forma.id"
                      :title="`${forma.descricao}`"
                    >
                      {{ forma.descricao }}
                    </option>
                  </select>
                  <span
                    v-if="!$v.lancamento.forma_pagamento_id.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </span>
                </div>
                <div class="form-group col-sm-6">
                  <label>Nº do documento: </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Número do documento"
                    v-model="lancamento.num_documento"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>Nota Fiscal: </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Número do documento"
                    v-model="lancamento.nota_fiscal"
                  />
                </div>
                <div class="form-group col-sm-6">
                  <label>Data de emissão da NF: </label>
                  <input
                    type="date"
                    class="form-control"
                    placeholder="Data de emissão"
                    v-model="lancamento.data_nota_fiscal"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              <i class="far fa-user"></i>
            </span>
            <span class="d-none d-sm-inline-block">Parcelar/Replicar</span>
          </template>
          <div class="form-row">
            <div class="form-group">
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="form-check-input"
                  id="contaAReceber"
                  value="1"
                  v-model="tipo_replicar"
                  @change="replicarParcelar"
                />
                <label class="form-check-label" for="contaAReceber">
                  Nenhum
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="form-check-input"
                  id="contaAPagar"
                  value="2"
                  v-model="tipo_replicar"
                />
                <label class="form-check-label" for="contaAPagar">
                  Parcelar
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="form-check-input"
                  id="entrada"
                  value="3"
                  v-model="tipo_replicar"
                />
                <label class="form-check-label" for="entrada"> Replicar </label>
              </div>
            </div>
          </div>
          <div v-if="tipo_replicar != 1" class="form-row">
            <div class="form-group col-sm-3">
              <label v-if="tipo_replicar == 2"
                >Nº de Parcelas: <span class="text-danger">*</span></label
              >
              <label v-if="tipo_replicar == 3"
                >Nº de repetições: <span class="text-danger">*</span></label
              >
              <input
                type="number"
                class="form-control"
                placeholder="00"
                v-model="num_replicar"
              />
            </div>
            <div class="form-group col-sm-2">
              <br />
              <button
                @click="replicarParcelar()"
                type="button"
                class="btn btn-primary"
                title="Calcular"
              >
                <span>Calcular</span>
              </button>
            </div>
          </div>
          <div v-if="tipo_replicar == 2">
            <div
              v-for="(conta, index) in parcelas"
              :key="index"
              class="form-row"
            >
              <div class="col-sm-3">
                <label>Título: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nome completo"
                  :name="'parcelas[' + index + '].[tituloParcela]'"
                  :id="'parcelas[' + index + ']'"
                  v-model="parcelas[index].titulo"
                />
              </div>
              <div class="col-sm-3">
                <label>Valor: </label>
                <div class="input-group">
                  <span
                    class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                  >
                    <span class="input-group-text">R$</span></span
                  >
                  <input
                    type="number"
                    class="form-control"
                    step="0.01"
                    :name="'parcelas[' + index + '].[valor]'"
                    :id="'parcelas[' + index + ']'"
                    v-model="parcelas[index].valor"
                    placeholder="000,00"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <label>Vencimento: <span class="text-danger">*</span></label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="Nome completo"
                  :name="'parcelas[' + index + '].[vencimento]'"
                  :id="'parcelas[' + index + ']'"
                  v-model="parcelas[index].vencimento"
                />
              </div>
              <div class="col-sm-3">
                <label>Competencia: <span class="text-danger">*</span></label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="Nome completo"
                  :name="'parcelas[' + index + '].[competenciaParcela]'"
                  :id="'parcelas[' + index + ']'"
                  v-model="parcelas[index].competenciaParcela"
                />
              </div>
            </div>
          </div>
          <div v-if="tipo_replicar == 3">
            <div
              v-for="(conta, index) in replicar"
              :key="index"
              class="form-row"
            >
              <div class="col-sm-3">
                <label>Título: <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nome completo"
                  :name="'replicar[' + index + '].[tituloReplicar]'"
                  :id="'replicar[' + index + ']'"
                  v-model="replicar[index].tituloReplicar"
                />
              </div>
              <div class="col-sm-3">
                <label>Valor: </label>
                <div class="input-group">
                  <span
                    class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                  >
                    <span class="input-group-text">R$</span></span
                  >
                  <input
                    type="number"
                    class="form-control"
                    step="0.01"
                    :name="'replicar[' + index + '].[valor]'"
                    :id="'replicar[' + index + ']'"
                    v-model="replicar[index].valor"
                    placeholder="000,00"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <label>Vencimento: <span class="text-danger">*</span></label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="Nome completo"
                  :name="'replicar[' + index + '].[vencimento]'"
                  :id="'replicar[' + index + ']'"
                  v-model="replicar[index].vencimento"
                />
              </div>
              <div class="col-sm-3">
                <label>Competencia: <span class="text-danger">*</span></label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="Nome completo"
                  :name="'replicar[' + index + '].[competenciaReplicar]'"
                  :id="'replicar[' + index + ']'"
                  v-model="replicar[index].competenciaReplicar"
                />
              </div>
            </div>
          </div>
          <br />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              <i class="far fa-user"></i>
            </span>
            <span class="d-none d-sm-inline-block">Centro de Custo</span>
          </template>
          <button
            type="button"
            @click="addRateio()"
            class="btn btn-primary"
            title="Adicionar conta"
          >
            <i class="bx ri-add-line"></i> Adicionar Item
          </button>
          <hr />
          <div v-for="(rat, index) in rateio" :key="index" class="form-row">
            <div class="form-group col-sm-3">
              <label>Centro de Custo: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                :name="'rateio[' + index + '].[centro_custo_id]'"
                :id="'rateio[' + index + ']'"
                v-model="rateio[index].centro_custo_id"
              >
                <option value="">Selecione um centro de custo</option>
                <option
                  v-for="centro in centrosDeCustos"
                  :key="centro.id"
                  :value="centro.id"
                  :title="`${centro.descricao}`"
                >
                  {{ centro.descricao }}
                </option>
              </select>
            </div>
            <div class="form-group col-sm-3">
              <label>Subcategoria: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                :name="'rateio[' + index + '].[subcategoria_contas_id_rateio]'"
                :id="'rateio[' + index + ']'"
                v-model="rateio[index].subcategoria_contas_id_rateio"
              >
                <option value="">Selecione uma subcategoria</option>
                <option
                  v-for="sub in subcategorias"
                  :key="sub.id"
                  :value="sub.id"
                  :title="`${sub.descricao}`"
                >
                  {{ sub.descricao }}
                </option>
              </select>
            </div>
            <div class="col-sm-2">
              <label>Porcentagem: <span class="text-danger">*</span></label>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  :name="'rateio[' + index + '].[porcentagem]'"
                  :id="'rateio[' + index + ']'"
                  v-model="rateio[index].porcentagem"
                  placeholder="000%"
                  @blur="calcularRateio(index, 'porcentagem')"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <label>Valor: <span class="text-danger">*</span></label>
              <div class="input-group">
                <span
                  class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                >
                  <span class="input-group-text">R$</span></span
                >
                <input
                  type="number"
                  class="form-control"
                  step="0.01"
                  :name="'rateio[' + index + '].[valor]'"
                  :id="'rateio[' + index + ']'"
                  v-model="rateio[index].valor"
                  placeholder="000,00"
                  @blur="calcularRateio(index, 'valor')"
                />
              </div>
            </div>
            <div class="form-group col-sm-1">
              <br />
              <button
                type="button"
                @click="deleteRateio(index)"
                class="btn btn-lg btn-outline-danger"
                title="Adicionar item"
              >
                <i class="bx ri-delete-bin-line"></i>
              </button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="submit"
          class="btn btn-primary"
          :title="textComponent"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textComponent }}</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  name: 'CreateEditLancamentoComponent',
  props: {
    lancamentoId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      lancamento: {
        titulo: '',
        data_vencimento: '',
        competencia: '',
        data_pagamento: '',
        tipo: 2,
        origem: '1',
        id_origem: '',
        juros: 0,
        multa: 0,
        desconto: 0,
        issrf: 0,
        outras_retencoes: 0,
        valor_base: '',
        num_documento: '',
        nota_fiscal: '',
        data_nota_fiscal: '',
        observacao: '',
        forma_pagamento_id: '',
        subcategoria_contas_id: '',
        unidade_id: '',
        conta_id: '',
      },
      num_replicar: '',
      tipo_replicar: 1,
      contas: null,
      clientes: null,
      fornecedores: null,
      users: null,
      formasPagamentos: null,
      subcategorias: null,
      centrosDeCustos: null,
      unidades: null,
      parcelas: [],
      replicar: [],
      rateio: [],
      disableButton: false,
    }
  },

  computed: {
    textComponent() {
      return this.lancamentoId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    // ADICIONAR RATEIO
    addRateio() {
      this.rateio.push({
        centro_custo_id: '',
        subcategoria_contas_id_rateio: '',
        valor: '',
        porcentagem: '',
      })
    },

    // DELETAR UM RATEIO
    deleteRateio(index) {
      this.rateio.splice(index, 1)
    },

    // CALCULAR RATEIO
    calcularRateio(index, campo) {
      /* eslint eqeqeq: 0 */
      if (campo == 'porcentagem') {
        const valorTotal =
          (parseFloat(this.rateio[index].porcentagem) *
            parseFloat(this.lancamento.valor_total)) /
          100
        this.rateio[index].valor = valorTotal.toFixed(2)
      } else if (campo == 'valor') {
        const valorPorcentagem =
          (parseFloat(this.rateio[index].valor) * 100) /
          parseFloat(this.lancamento.valor_total)
        this.rateio[index].porcentagem = Math.round(valorPorcentagem)
      }
    },

    // FORMATAR VALOR TOTAL E CAMPOS DE DINHEIRO
    async formatarValor(valorVar, campo) {
      this.$set(this.lancamento, campo, valorVar.toFixed(2))

      // calculo do valor total
      this.lancamento.valor_total = (
        parseFloat(this.lancamento.valor_base) +
        parseFloat(this.lancamento.juros) +
        parseFloat(this.lancamento.multa) -
        parseFloat(this.lancamento.issrf) -
        parseFloat(this.lancamento.outras_retencoes) -
        parseFloat(this.lancamento.desconto)
      ).toFixed(2)
    },

    // REPLICAR OU PARCELAR O PAGAMENTO
    async replicarParcelar() {
      this.parcelas = []
      this.replicar = []

      /* eslint eqeqeq: 0 */
      if (this.tipo_replicar == 2) {
        const currentDateVencimento = new Date(this.lancamento.vencimento)
        const currentDateCompetencia = new Date(this.lancamento.competencia)
        currentDateVencimento.setDate(currentDateVencimento.getDate() + 1)
        currentDateCompetencia.setDate(currentDateCompetencia.getDate() + 1)
        const valorParcela =
          parseFloat(this.lancamento.valor_total) /
          parseFloat(this.num_replicar)
        for (let i = 1; i <= this.num_replicar; i += 1) {
          this.parcelas.push({
            tituloParcela: `${this.lancamento.titulo} ${i}/${this.num_replicar}`,
            valor: valorParcela.toFixed(2),
            vencimento: moment(currentDateVencimento).format('YYYY-MM-DD'),
            competenciaParcela: moment(currentDateCompetencia).format(
              'YYYY-MM-DD',
            ),
          })
          currentDateVencimento.setMonth(currentDateVencimento.getMonth() + 1)
          currentDateCompetencia.setMonth(currentDateCompetencia.getMonth() + 1)
        }
      }

      /* eslint eqeqeq: 0 */
      if (this.tipo_replicar == 3) {
        const currentDateVencimento = new Date(this.lancamento.vencimento)
        const currentDateCompetencia = new Date(this.lancamento.competencia)
        currentDateVencimento.setDate(currentDateVencimento.getDate() + 1)
        currentDateCompetencia.setDate(currentDateCompetencia.getDate() + 1)
        for (let i = 1; i <= this.num_replicar; i += 1) {
          this.replicar.push({
            tituloReplicar: `${this.lancamento.titulo} ${i}/${this.num_replicar}`,
            valor: this.lancamento.valor_total,
            vencimento: moment(currentDateVencimento).format('YYYY-MM-DD'),
            competenciaReplicar: moment(currentDateCompetencia).format(
              'YYYY-MM-DD',
            ),
          })
          currentDateVencimento.setMonth(currentDateVencimento.getMonth() + 1)
          currentDateCompetencia.setMonth(currentDateCompetencia.getMonth() + 1)
        }
      }
    },

    async getLancamento() {
      try {
        this.lancamento = await this.$store.dispatch(
          'getLancamento',
          this.lancamentoId,
        )
        this.rateio = await this.$store.dispatch(
          'getRateioByLancamento',
          this.lancamentoId,
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getClients() {
      try {
        this.clientes = await this.$store.dispatch('getClients')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCentrosCustos() {
      try {
        this.centrosDeCustos = await this.$store.dispatch('getCentrosCustos')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getFornecedores() {
      try {
        this.fornecedores = await this.$store.dispatch('getFornecedores')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getUsers() {
      try {
        this.users = await this.$store.dispatch('getUsers')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getUnidades() {
      try {
        this.unidades = await this.$store.dispatch('getUnidades')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getFormasPagamentos() {
      try {
        this.formasPagamentos = await this.$store.dispatch(
          'getFormasPagamentos',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getSubcategorias() {
      try {
        this.subcategorias = await this.$store.dispatch(
          'getSubcategoriasContas',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getContas() {
      try {
        this.contas = await this.$store.dispatch('getContas')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.lancamentoId) {
            response = await this.$store.dispatch('updateLancamento', {
              id: this.lancamentoId,
              lancamento: this.lancamento,
              parcelas: this.parcelas,
              replicar: this.replicar,
              rateio: this.rateio,
            })
          } else {
            response = await this.$store.dispatch('saveLancamento', {
              id: this.lancamentoId,
              lancamento: this.lancamento,
              parcelas: this.parcelas,
              replicar: this.replicar,
              rateio: this.rateio,
            })
          }
          this.$refs.saveLancamento.reset()
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
          })
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    lancamento: {
      titulo: {
        required,
      },
      data_vencimento: {
        required,
      },
      competencia: {
        required,
      },
      id_origem: {
        required,
      },
      valor_base: {
        required,
      },
      forma_pagamento_id: {
        required,
      },
      subcategoria_contas_id: {
        required,
      },
      conta_id: {
        required,
      },
      unidade_id: {
        required,
      },
    },
  },

  mounted() {
    this.getContas()
    this.getFornecedores()
    this.getSubcategorias()
    this.getClients()
    this.getUsers()
    this.getFormasPagamentos()
    this.getFormasPagamentos()
    this.getUnidades()
    this.getCentrosCustos()
    if (this.lancamentoId) {
      this.getLancamento()
    }
  },
}
</script>
