<template>
  <div>
    <FiltroLancamentos ref="list" />
    <b-modal
      id="modalCreateEditLancamento"
      title="Baixar Lançamento"
      title-class="font-18"
      hide-footer
      no-close-on-backdrop
      @hidden="$emit('closeModal', false)"
    >
      <form @submit.prevent="save">
        <div class="form-row">
          <div class="col-sm-12">
            <h3>{{ lancamento.id }} - {{ lancamento.titulo }}</h3>
            <h5>{{ lancamento.cliente_nome }}</h5>
            <div class="form-group">
              <label>Valor: </label><br />
              {{ valorBaseFormated }}
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Juros: </label>
                <div class="input-group">
                  <span
                    class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                  >
                    <span class="input-group-text">R$</span></span
                  >
                  <input
                    type="number"
                    class="form-control"
                    step="0.01"
                    value="0,00"
                    v-model.number="lancamento.juros"
                    placeholder="000,00"
                    @blur="formatarValor(lancamento.juros, 'juros')"
                  />
                </div>
              </div>
              <div class="form-group col-sm-6">
                <label>Multa: </label>
                <div class="input-group">
                  <span
                    class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                  >
                    <span class="input-group-text">R$</span></span
                  >
                  <input
                    type="number"
                    class="form-control"
                    step="0.01"
                    value="0,00"
                    v-model.number="lancamento.multa"
                    placeholder="000,00"
                    @blur="formatarValor(lancamento.multa, 'multa')"
                  />
                </div>
              </div>
              <div class="form-group col-sm-6">
                <label>ISSRF: </label>
                <div class="input-group">
                  <span
                    class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                  >
                    <span class="input-group-text">R$</span></span
                  >
                  <input
                    type="number"
                    class="form-control"
                    step="0.01"
                    value="0,00"
                    v-model.number="lancamento.issrf"
                    placeholder="000,00"
                    @blur="formatarValor(lancamento.issrf, 'issrf')"
                  />
                </div>
              </div>
              <div class="form-group col-sm-6">
                <label>Outras retenções: </label>
                <div class="input-group">
                  <span
                    class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                  >
                    <span class="input-group-text">R$</span></span
                  >
                  <input
                    type="number"
                    class="form-control"
                    step="0.01"
                    value="0,00"
                    v-model.number="lancamento.outras_retencoes"
                    placeholder="000,00"
                    @blur="
                      formatarValor(
                        lancamento.outras_retencoes,
                        'outras_retencoes',
                      )
                    "
                  />
                </div>
              </div>
              <div class="form-group col-sm-6">
                <label>Desconto: </label>
                <div class="input-group">
                  <span
                    class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                  >
                    <span class="input-group-text">R$</span></span
                  >
                  <input
                    type="number"
                    class="form-control"
                    step="0.01"
                    value="0,00"
                    v-model.number="lancamento.desconto"
                    placeholder="000,00"
                    @blur="formatarValor(lancamento.desconto, 'desconto')"
                  />
                </div>
              </div>
              <div class="form-group col-sm-6">
                <label>Valor Total: </label>
                <div class="input-group">
                  <span
                    class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                  >
                    <span class="input-group-text">R$</span></span
                  >
                  <input
                    type="number"
                    class="form-control"
                    step="0.01"
                    v-model.number="lancamento.valor_total"
                    placeholder="000,00"
                    readonly
                  />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label
                  >Forma de Pagamento: <span class="text-danger">*</span></label
                >
                <select
                  class="form-control"
                  :class="{
                    'is-invalid': $v.lancamento.forma_pagamento_id.$error,
                  }"
                  v-model="$v.lancamento.forma_pagamento_id.$model"
                >
                  <option value="">Selecione uma forma de pagamento</option>
                  <option
                    v-for="forma in formasPagamentos"
                    :key="forma.id"
                    :value="forma.id"
                    :title="`${forma.descricao}`"
                  >
                    {{ forma.descricao }}
                  </option>
                </select>
                <span
                  v-if="!$v.lancamento.forma_pagamento_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Nº do documento: </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Número do documento"
                  v-model="lancamento.num_documento"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label
                  >Data do pagamento: <span class="text-danger">*</span></label
                >
                <input
                  type="date"
                  class="form-control"
                  placeholder="Date de pagamento"
                  :class="{
                    'is-invalid': $v.lancamento.data_pagamento.$error,
                  }"
                  v-model="$v.lancamento.data_pagamento.$model"
                />
                <span
                  v-if="!$v.lancamento.data_pagamento.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Conta: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.lancamento.conta_id.$error }"
                  v-model="$v.lancamento.conta_id.$model"
                >
                  <option value="">Selecione uma conta</option>
                  <option
                    v-for="conta in contas"
                    :key="conta.id"
                    :value="conta.id"
                    :title="`${conta.descricao}`"
                  >
                    {{ conta.descricao }}
                  </option>
                </select>
                <span
                  v-if="!$v.lancamento.conta_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="form-group">
              <label>Filial: <span class="text-danger">*</span></label>
              <select
                class="form-control"
                :class="{ 'is-invalid': $v.lancamento.unidade_id.$error }"
                v-model="$v.lancamento.unidade_id.$model"
              >
                <option value="">Selecione uma filial</option>
                <option
                  v-for="unidade in unidades"
                  :key="unidade.id"
                  :value="unidade.id"
                  :title="`${unidade.descricao}`"
                >
                  {{ unidade.descricao }}
                </option>
              </select>
              <span
                v-if="!$v.lancamento.unidade_id.required"
                class="invalid-feedback"
              >
                O campo é obrigatório
              </span>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Nota Fiscal: </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Número do documento"
                  v-model="lancamento.nota_fiscal"
                />
              </div>
              <div class="form-group col-sm-6">
                <label>Data de emissão da NF: </label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data de emissão"
                  v-model="lancamento.data_nota_fiscal"
                />
              </div>
            </div>
            <div class="form-group">
              <label>Observação:</label><br />
              {{ lancamento.observacao }}
            </div>
          </div>
        </div>
        <hr />
        <div class="modal-footer-custom">
          <button
            type="button"
            class="btn btn-secondary"
            title="Cancelar"
            @click="$emit('closeModal', false)"
          >
            Cancelar
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            title="Baixar Lançamento"
            :disabled="$v.$invalid || disableButton"
          >
            <i
              v-if="disableButton"
              class="spinner-grow spinner-grow-custom text-wither"
            />
            <span v-else>Baixar lançamento</span>
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { moneyToCurrencyBr } from '@/utils/strings'
import moment from 'moment'
import FiltroLancamentos from '@/components/financeiro/lancamentos/FiltroLancamentos.vue'

export default {
  name: 'BaixaPagamentoModal',

  props: {
    lancamentoId: {
      type: String,
      default: null,
    },
  },

  components: {
    FiltroLancamentos,
  },

  data() {
    return {
      lancamento: {
        titulo: '',
        data_vencimento: '',
        competencia: '',
        data_pagamento: '',
        tipo: 2,
        origem: '1',
        id_origem: '',
        juros: 0.0,
        multa: 0.0,
        desconto: 0.0,
        issrf: 0.0,
        outras_retencoes: 0.0,
        valor_base: '',
        num_documento: '',
        nota_fiscal: '',
        data_nota_fiscal: '',
        observacao: '',
        forma_pagamento_id: '',
        subcategoria_contas_id: '',
        unidade_id: '',
        conta_id: '',
        cliente_nome: '',
      },
      contas: null,
      clientes: null,
      fornecedores: null,
      users: null,
      unidades: null,
      formasPagamentos: null,
      subcategorias: null,
      disableButton: false,
    }
  },

  computed: {
    textModal() {
      return this.lancamentoId ? 'Atualizar' : 'Cadastrar'
    },

    valorBaseFormated() {
      return moneyToCurrencyBr(this.lancamento.valor_base)
    },
  },

  methods: {
    // FORMATAR VALOR TOTAL E CAMPOS DE DINHEIRO
    async formatarValor(valorVar, campo) {
      this.$set(this.lancamento, campo, valorVar.toFixed(2))

      // calculo do valor total
      this.lancamento.valor_total = (
        parseFloat(this.lancamento.valor_base) +
        parseFloat(this.lancamento.juros) +
        parseFloat(this.lancamento.multa) -
        parseFloat(this.lancamento.issrf) -
        parseFloat(this.lancamento.outras_retencoes) -
        parseFloat(this.lancamento.desconto)
      ).toFixed(2)
    },
    async getLancamento() {
      try {
        this.lancamento = await this.$store.dispatch(
          'getLancamento',
          this.lancamentoId,
        )
        this.$bvModal.show('modalCreateEditLancamento')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getUnidades() {
      try {
        this.unidades = await this.$store.dispatch('getUnidades')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getClients() {
      try {
        this.clientes = await this.$store.dispatch('getClients')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getFornecedores() {
      try {
        this.fornecedores = await this.$store.dispatch('getFornecedores')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getUsers() {
      try {
        this.users = await this.$store.dispatch('getUsers')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getFormasPagamentos() {
      try {
        this.formasPagamentos = await this.$store.dispatch(
          'getFormasPagamentos',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getSubcategorias() {
      try {
        this.subcategorias = await this.$store.dispatch('getSubcategorias')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getContas() {
      try {
        this.contas = await this.$store.dispatch('getContas')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          response = await this.$store.dispatch('baixarPagamento', {
            id: this.lancamentoId,
            lancamento: this.lancamento,
          })
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    lancamento: {
      forma_pagamento_id: {
        required,
      },
      conta_id: {
        required,
      },
      unidade_id: {
        required,
      },
      data_pagamento: {
        required,
      },
    },
  },

  async mounted() {
    this.getContas()
    this.getFornecedores()
    this.getClients()
    this.getUsers()
    this.getUnidades()
    this.getFormasPagamentos()
    const currentDate = new Date()
    this.dataFinal = moment(currentDate).format('YYYY-MM-DD')

    if (this.lancamentoId) {
      await this.getLancamento()
      // eslint-disable-next-line no-shadow
      const currentDate = new Date()
      this.lancamento.data_pagamento = moment(currentDate).format('YYYY-MM-DD')
    } else {
      this.$bvModal.show('modalCreateEditLancamento')
    }
  },
}
</script>
