<template>
  <div>
    <div>
      <b-modal
        id="modalLancarContratos"
        size="xl"
        title="Lançamento de Contratos (Faturar)"
        title-class="font-18"
        hide-footer
        no-close-on-backdrop
        @hidden="$emit('closeModal', false)"
      >
        <form @submit.prevent="save">
          <div class="col-lg-12">
            <b-tabs
              justified
              nav-class="nav-tabs-custom"
              content-class="p-3 text-muted"
            >
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"
                    >Dados do Contrato</span
                  >
                </template>
                <div class="form-row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <p>
                        Tipo:
                        <span class="text-danger">*</span>
                      </p>
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="contaAReceber"
                          value="1"
                          v-model="dadosLancamento.tipo"
                          name="tipoLancamento"
                        />
                        <label class="form-check-label" for="contaAReceber">
                          A Receber
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="entrada"
                          value="3"
                          v-model="dadosLancamento.tipo"
                          name="tipoLancamento"
                        />
                        <label class="form-check-label" for="entrada">
                          Entrada
                        </label>
                      </div>
                    </div>
                    <div v-if="dadosLancamento.tipo == 3" class="form-row">
                      <div class="form-group col-sm-6">
                        <label>Data de pagamento: </label>
                        <input
                          type="date"
                          class="form-control"
                          placeholder="Data de pagamento"
                          v-model="dadosLancamento.data_pagamento"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label
                        >Subcategoria: <span class="text-danger">*</span></label
                      >
                      <select
                        class="form-control"
                        :class="{
                          'is-invalid':
                            $v.dadosLancamento.subcategoria_contas_id.$error,
                        }"
                        v-model="
                          $v.dadosLancamento.subcategoria_contas_id.$model
                        "
                      >
                        <option value="">Selecione uma subcategoria</option>
                        <option
                          v-for="sub in subcategorias"
                          :key="sub.id"
                          :value="sub.id"
                          :title="`${sub.descricao}`"
                        >
                          {{ sub.descricao }}
                        </option>
                      </select>
                      <span
                        v-if="
                          !$v.dadosLancamento.subcategoria_contas_id.required
                        "
                        class="invalid-feedback"
                      >
                        O campo é obrigatório
                      </span>
                    </div>
                    <div class="form-group">
                      <label>Conta: <span class="text-danger">*</span></label>
                      <select
                        class="form-control"
                        :class="{
                          'is-invalid': $v.dadosLancamento.conta_id.$error,
                        }"
                        v-model="$v.dadosLancamento.conta_id.$model"
                      >
                        <option value="">Selecione uma conta</option>
                        <option
                          v-for="conta in contas"
                          :key="conta.id"
                          :value="conta.id"
                          :title="`${conta.descricao}`"
                        >
                          {{ conta.descricao }}
                        </option>
                      </select>
                      <span
                        v-if="!$v.dadosLancamento.conta_id.required"
                        class="invalid-feedback"
                      >
                        O campo é obrigatório
                      </span>
                    </div>
                    <div class="form-group">
                      <label>Filial: <span class="text-danger">*</span></label>
                      <select
                        class="form-control"
                        :class="{
                          'is-invalid': $v.dadosLancamento.unidade_id.$error,
                        }"
                        v-model="$v.dadosLancamento.unidade_id.$model"
                      >
                        <option value="">Selecione uma filial</option>
                        <option
                          v-for="unidade in unidades"
                          :key="unidade.id"
                          :value="unidade.id"
                          :title="`${unidade.descricao}`"
                        >
                          {{ unidade.descricao }}
                        </option>
                      </select>
                      <span
                        v-if="!$v.dadosLancamento.unidade_id.required"
                        class="invalid-feedback"
                      >
                        O campo é obrigatório
                      </span>
                    </div>
                  </div>

                  <!-- 2ª coluna -->
                  <div class="col-sm-6">
                    <div class="form-row">
                      <div class="form-group col-sm-6">
                        <label
                          >Forma de Pagamento:
                          <span class="text-danger">*</span></label
                        >
                        <select
                          class="form-control"
                          :class="{
                            'is-invalid':
                              $v.dadosLancamento.forma_pagamento_id.$error,
                          }"
                          v-model="$v.dadosLancamento.forma_pagamento_id.$model"
                        >
                          <option value="">
                            Selecione uma forma de pagamento
                          </option>
                          <option
                            v-for="forma in formasPagamentos"
                            :key="forma.id"
                            :value="forma.id"
                            :title="`${forma.descricao}`"
                          >
                            {{ forma.descricao }}
                          </option>
                        </select>
                        <span
                          v-if="!$v.dadosLancamento.forma_pagamento_id.required"
                          class="invalid-feedback"
                        >
                          O campo é obrigatório
                        </span>
                      </div>
                      <div class="form-group col-sm-6">
                        <label>Nº do documento: </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Número do documento"
                          v-model="dadosLancamento.num_documento"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-sm-6">
                        <label>Nota Fiscal: </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Número do documento"
                          v-model="dadosLancamento.nota_fiscal"
                        />
                      </div>
                      <div class="form-group col-sm-6">
                        <label>Data de emissão da NF: </label>
                        <input
                          type="date"
                          class="form-control"
                          placeholder="Data de emissão"
                          v-model="dadosLancamento.data_nota_fiscal"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <hr />
          <div class="modal-footer-custom">
            <button
              type="submit"
              class="btn btn-primary"
              title="Lançar"
              :disabled="$v.$invalid || disableButton"
            >
              <i
                v-if="disableButton"
                class="spinner-grow spinner-grow-custom text-wither"
              />
              <span v-else>Lançar</span>
            </button>
          </div>
        </form>
      </b-modal>
    </div>
    <div
      class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
    >
      <h5 class="card-title mb-0">Contratos</h5>
      <div
        v-if="
          getModuleUserByCode('1.01.002') && contratosSelecionados.length > 0
        "
      >
        <button
          type="button"
          class="btn btn-primary"
          title="Nova Conta"
          @click="showModal()"
        >
          <i class="ri-exchange-dollar-line align-middle"></i>Lançar
          Selecionados
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Mostrar
            <b-form-select
              v-model="table.perPage"
              size="sm"
              :options="table.pageOptions"
            />
            registros
          </label>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Pesquisar:
            <b-form-input
              type="search"
              class="form-control form-control-sm ml-2"
              placeholder="Pesquisar..."
              v-model="table.filter"
            />
          </label>
        </div>
      </div>
    </div>
    <div class="table-responsive mb-0">
      <b-table
        class="table-striped"
        :items="contratos"
        :fields="table.fields"
        responsive="sm"
        :per-page="table.perPage"
        :current-page="table.currentPage"
        :sort-by.sync="table.sortBy"
        :sort-desc.sync="table.sortDesc"
        :filter="table.filter"
        :filter-included-fields="table.filterOn"
        @filtered="onFiltered"
      >
        <template #head(selecionar)>
          <input
            type="checkbox"
            v-model="selecionarTudo"
            @change="selecionarTodos($event)"
          />
        </template>
        <template #cell(selecionar)="row">
          <input
            type="checkbox"
            v-model="row.item.selecionado"
            @change="adicionarOuRemoverContrato(row.item)"
          />
        </template>
        <template #cell(titulo)="row">
          <span v-html="row.value"></span>
        </template>
        <template #cell(data_inicio)="row">
          <span v-html="row.value" class="text-center"></span>
        </template>
        <template #cell(data_final)="row">
          <span v-html="row.value"></span>
        </template>
        <template #cell(parcelas_pagamento)="row">
          <span v-html="row.value"></span>
        </template>
        <template #cell(valor_total)="row">
          <span v-html="row.value"></span>
        </template>
        <template #cell(actions)="row">
          <button
            v-if="getModuleUserByCode('2.01.003')"
            type="button"
            class="btn btn-outline-secondary"
            title="Editar"
            @click="$emit('lancarContrato', row.item.id)"
          >
            <i class="ri-exchange-dollar-line align-middle"></i>
            Lançar
          </button>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col d-inline-flex align-items-center">
        Quantidade de registros: {{ contratos.length }}
        <span v-if="table.filter != null && table.filter != ''">
          &nbsp;(Filtrados: {{ table.totalRows }})
        </span>
      </div>
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination
              v-model="table.currentPage"
              :total-rows="table.totalRows"
              :per-page="table.perPage"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { moneyToCurrencyBr } from '@/utils/strings'
import { dateSqlToDateBR } from '@/utils/date'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ListPreLancamento',

  props: {
    contratos: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      table: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: 'titulo',
        sortDesc: false,
        fields: [
          {
            key: 'selecionar',
            label: '',
            tdClass: 'text-center align-middle',
            thClass: 'text-center align-middle',
          },
          {
            key: 'titulo',
            label: 'Contrato',
            formatter: (value, key, item) => {
              return `<h6 class="d-none d-sm-inline-block text-bold">#${item.id} - ${item.titulo}</h6> <br> ${item.name}`
            },
          },
          {
            key: 'data_inicio',
            label: 'Data Início',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            tdClass: 'text-center align-middle',
            thClass: 'text-center align-middle',
            formatter: (value, key, item) => {
              return `<h6 class="d-none d-sm-inline-block text-bold">${dateSqlToDateBR(
                item.data_inicio,
              )}</h6> <br> Início`
            },
          },
          {
            key: 'data_final',
            label: 'Data Final',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            tdClass: 'text-center align-middle',
            thClass: 'text-center align-middle',
            formatter: (value, key, item) => {
              return `<h6 class="d-none d-sm-inline-block text-bold">${dateSqlToDateBR(
                item.data_final,
              )}</h6> <br> Final`
            },
          },
          {
            key: 'parcelas_pagamento',
            label: 'Pagamento',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            tdClass: 'text-center align-middle',
            thClass: 'text-center align-middle',
            formatter: (value, key, item) => {
              return `<h6 class="d-none d-sm-inline-block text-bold"> ${item.parcelas_pagamento} vezes</h6> <br> ${item.forma_pagamento_descricao}`
            },
          },
          {
            key: 'valor_total',
            label: 'Valor Total',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            tdClass: 'text-center align-middle',
            thClass: 'text-center align-middle',
            formatter: (value, key, item) => {
              return `<h6 class="d-none d-sm-inline-block text-bold"> ${moneyToCurrencyBr(
                item.valor_contrato,
              )}</h6>`
            },
          },
          {
            key: 'actions',
            label: 'Ações',
            tdClass: 'text-center align-middle',
            thClass: 'text-center align-middle',
            thStyle: { width: '130px' },
          },
        ],
      },
      contratosSelecionados: [],
      selecionarTudo: false,
      dadosLancamento: {
        data_pagamento: null,
        tipo: 1,
        origem: 1,
        num_documento: '',
        nota_fiscal: '',
        data_nota_fiscal: '',
        forma_pagamento_id: '',
        subcategoria_contas_id: '',
        unidade_id: '',
        conta_id: '',
      },
      formasPagamentos: null,
      subcategorias: null,
      unidades: null,
      contas: null,
      disableButton: false,
      showModalLancarContratos: false,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    showModal() {
      this.showModalLancarContratos = true
      this.$bvModal.show('modalLancarContratos')
    },

    closeModal() {
      this.showModalLancarContratos = false
    },
    onFiltered(filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },

    adicionarOuRemoverContrato(contrato) {
      if (contrato.selecionado) {
        // Se o contrato foi selecionado, adicione-o ao array contratosSelecionados
        this.contratosSelecionados.push(contrato)
      } else {
        // Se o contrato foi desmarcado, remova-o do array contratosSelecionados (se estiver presente)
        const indexSelecionado = this.contratosSelecionados.findIndex(
          (c) => c.id === contrato.id,
        )
        if (indexSelecionado !== -1) {
          this.contratosSelecionados.splice(indexSelecionado, 1)
        }
      }
    },

    selecionarTodos(event) {
      this.contratos.forEach((contrato, index) => {
        // Faz uma cópia do objeto contrato
        const contratoAtualizado = { ...contrato }
        // Modifica a propriedade selecionado do contrato atualizado
        contratoAtualizado.selecionado = event.target.checked
        // Atualiza o contrato original no array contratos
        this.$set(this.contratos, index, contratoAtualizado)
      })

      if (event.target.checked) {
        this.contratosSelecionados = [...this.contratos]
      } else {
        this.contratosSelecionados = []
      }
    },

    async getUnidades() {
      try {
        this.unidades = await this.$store.dispatch('getUnidades')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getFormasPagamentos() {
      try {
        this.formasPagamentos = await this.$store.dispatch(
          'getFormasPagamentos',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getSubcategorias() {
      try {
        this.subcategorias = await this.$store.dispatch(
          'getSubcategoriasContas',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getContas() {
      try {
        this.contas = await this.$store.dispatch('getContas')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          response = await this.$store.dispatch('savePreLancamentoArray', {
            dadosLancamento: this.dadosLancamento,
            contratosSelecionados: this.contratosSelecionados,
          })
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
          })
          setTimeout(() => {
            window.location.reload()
          })
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    dadosLancamento: {
      forma_pagamento_id: {
        required,
      },
      subcategoria_contas_id: {
        required,
      },
      conta_id: {
        required,
      },
      unidade_id: {
        required,
      },
    },
  },

  mounted() {
    this.getContas()
    this.getSubcategorias()
    this.getFormasPagamentos()
    this.getUnidades()
    this.table.totalRows = this.contratos.length
  },
}
</script>
