<template>
  <b-modal
    id="modalLancarContrato"
    size="xl"
    title="Lançamento de Contratos (Faturar)"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="col-lg-12">
        <b-tabs
          justified
          nav-class="nav-tabs-custom"
          content-class="p-3 text-muted"
        >
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Dados do Contrato</span>
            </template>
            <div class="form-row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Titulo: <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.lancamento.titulo.$error }"
                    placeholder="Descrição do lancamento"
                    v-model="$v.lancamento.titulo.$model"
                    readonly
                  />
                  <span
                    v-if="!$v.lancamento.titulo.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </span>
                </div>
                <div class="form-row"></div>
                <div class="form-group">
                  <p>
                    Tipo:
                    <span class="text-danger">*</span>
                  </p>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="contaAReceber"
                      value="1"
                      v-model="lancamento.tipo"
                      name="tipoLancamento"
                    />
                    <label class="form-check-label" for="contaAReceber">
                      A Receber
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="entrada"
                      value="3"
                      v-model="lancamento.tipo"
                      name="tipoLancamento"
                    />
                    <label class="form-check-label" for="entrada">
                      Entrada
                    </label>
                  </div>
                </div>
                <div v-if="lancamento.tipo == 3" class="form-row">
                  <div class="form-group col-sm-6">
                    <label>Data de pagamento: </label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Data de pagamento"
                      v-model="lancamento.data_pagamento"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label
                    >Subcategoria: <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-control"
                    :class="{
                      'is-invalid': $v.lancamento.subcategoria_contas_id.$error,
                    }"
                    v-model="$v.lancamento.subcategoria_contas_id.$model"
                  >
                    <option value="">Selecione uma subcategoria</option>
                    <option
                      v-for="sub in subcategorias"
                      :key="sub.id"
                      :value="sub.id"
                      :title="`${sub.descricao}`"
                    >
                      {{ sub.descricao }}
                    </option>
                  </select>
                  <span
                    v-if="!$v.lancamento.subcategoria_contas_id.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </span>
                </div>
                <div class="form-group">
                  <label>Conta: <span class="text-danger">*</span></label>
                  <select
                    class="form-control"
                    :class="{ 'is-invalid': $v.lancamento.conta_id.$error }"
                    v-model="$v.lancamento.conta_id.$model"
                  >
                    <option value="">Selecione uma conta</option>
                    <option
                      v-for="conta in contas"
                      :key="conta.id"
                      :value="conta.id"
                      :title="`${conta.descricao}`"
                    >
                      {{ conta.descricao }}
                    </option>
                  </select>
                  <span
                    v-if="!$v.lancamento.conta_id.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </span>
                </div>
                <div class="form-group">
                  <label>Filial: <span class="text-danger">*</span></label>
                  <select
                    class="form-control"
                    :class="{ 'is-invalid': $v.lancamento.unidade_id.$error }"
                    v-model="$v.lancamento.unidade_id.$model"
                  >
                    <option value="">Selecione uma filial</option>
                    <option
                      v-for="unidade in unidades"
                      :key="unidade.id"
                      :value="unidade.id"
                      :title="`${unidade.descricao}`"
                    >
                      {{ unidade.descricao }}
                    </option>
                  </select>
                  <span
                    v-if="!$v.lancamento.unidade_id.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </span>
                </div>
              </div>

              <!-- 2ª coluna -->
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Valor: <span class="text-danger">*</span></label>
                  <div class="input-group">
                    <span
                      class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                    >
                      <span class="input-group-text">R$</span></span
                    >
                    <input
                      type="number"
                      class="form-control"
                      step="0.01"
                      :class="{
                        'is-invalid': $v.lancamento.valor_contrato.$error,
                      }"
                      v-model.number="$v.lancamento.valor_contrato.$model"
                      placeholder="000,00"
                      readonly
                    />
                  </div>
                  <span
                    v-if="!$v.lancamento.valor_contrato.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </span>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <label
                      >Forma de Pagamento:
                      <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-control"
                      :class="{
                        'is-invalid': $v.lancamento.forma_pagamento_id.$error,
                      }"
                      v-model="$v.lancamento.forma_pagamento_id.$model"
                    >
                      <option value="">Selecione uma forma de pagamento</option>
                      <option
                        v-for="forma in formasPagamentos"
                        :key="forma.id"
                        :value="forma.id"
                        :title="`${forma.descricao}`"
                      >
                        {{ forma.descricao }}
                      </option>
                    </select>
                    <span
                      v-if="!$v.lancamento.forma_pagamento_id.required"
                      class="invalid-feedback"
                    >
                      O campo é obrigatório
                    </span>
                  </div>
                  <div class="form-group col-sm-6">
                    <label>Nº do documento: </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Número do documento"
                      v-model="lancamento.num_documento"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <label>Nota Fiscal: </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Número do documento"
                      v-model="lancamento.nota_fiscal"
                    />
                  </div>
                  <div class="form-group col-sm-6">
                    <label>Data de emissão da NF: </label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Data de emissão"
                      v-model="lancamento.data_nota_fiscal"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block"
                >Condições de pagamento</span
              >
            </template>
            <div class="form-row" v-if="parcelas.length > 0">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title text-center">Parcelas</h4>
                    <div class="table-responsive">
                      <table class="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th>Parcela</th>
                            <th>Data de vencimento</th>
                            <th>Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(parcela, index) in parcelas" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>
                              <input
                                type="date"
                                class="form-control"
                                placeholder="Data da parcela"
                                :name="'parcelas[' + index + '].[vencimento]'"
                                :id="'parcelas[' + index + ']'"
                                v-model="parcelas[index].vencimento"
                                @blur="dataParcelamento(index)"
                              />
                            </td>
                            <td>
                              <div class="input-group">
                                <span
                                  class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                                >
                                  <span class="input-group-text">R$</span></span
                                >
                                <input
                                  type="number"
                                  step="0.01"
                                  class="form-control"
                                  placeholder="Valor da parcela"
                                  :name="
                                    'parcelas[' + index + '].[valor_parcela]'
                                  "
                                  :id="'parcelas[' + index + ']'"
                                  v-model="parcelas[index].valor_parcela"
                                  @blur="parcelamento2(index)"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Contas Adicionais</span>
            </template>
            <div role="tablist">
              <div
                v-for="(conta, index) in contas_adicionais"
                :key="index"
                class="card task-box"
              >
                <b-card no-body class="mb-1 shadow-none">
                  <b-card-header header-tag="header" role="tab">
                    <div class="form-row">
                      <div class="form-group col-sm-1">
                        <br />
                        <a
                          v-b-toggle="'accordion-' + index"
                          class="btn btn-lg btn-rounded btn-outline-info"
                          href="javascript: void(0);"
                          >{{ index + 1 }}</a
                        >
                      </div>
                      <div class="form-group col-sm-11">
                        <label
                          >Descrição: <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Nome completo"
                          :name="'contas_adicionais[' + index + '].[descricao]'"
                          :id="'contas_adicionais[' + index + ']'"
                          v-model="contas_adicionais[index].descricao"
                          readonly
                        />
                      </div>
                    </div>
                  </b-card-header>
                  <b-collapse
                    :id="'accordion-' + index"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <div class="form-row">
                        <div class="form-group col-sm-3">
                          <label
                            >Valor: <span class="text-danger">*</span></label
                          >
                          <div class="input-group">
                            <span
                              class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                            >
                              <span class="input-group-text">R$</span></span
                            >
                            <input
                              type="number"
                              step="0.01"
                              class="form-control"
                              placeholder="Valor da conta"
                              :name="
                                'contas_adicionais[' +
                                index +
                                '].[valor_parcela]'
                              "
                              :id="'contas_adicionais[' + index + ']'"
                              v-model="contas_adicionais[index].valor"
                              @blur="parcelamentoConta(index)"
                            />
                          </div>
                        </div>
                        <div class="form-group col-sm-3">
                          <label
                            >Condição de Pagamento:
                            <span class="text-danger">*</span></label
                          >
                          <select
                            class="form-control"
                            :name="
                              'contas_adicionais[' +
                              index +
                              '].[condicao_pagamento]'
                            "
                            :id="'contas_adicionais[' + index + ']'"
                            v-model="
                              contas_adicionais[index].condicao_pagamento
                            "
                            @blur="condicaoPagamentoConta(index)"
                          >
                            <option value="">Selecione a condição</option>
                            <option value="1">À Vista</option>
                            <option value="2">Parcelado</option>
                          </select>
                        </div>
                        <div
                          v-if="
                            contas_adicionais[index].condicao_pagamento == 2
                          "
                          class="form-group col-sm-3"
                        >
                          <label
                            >Nº Parcelas:
                            <span class="text-danger">*</span></label
                          >
                          <input
                            type="number"
                            class="form-control"
                            placeholder="00"
                            :name="
                              'contas_adicionais[' + index + '].[parcelas]'
                            "
                            :id="'contas_adicionais[' + index + ']'"
                            v-model="contas_adicionais[index].parcelas"
                            @blur="parcelamentoConta(index)"
                          />
                        </div>
                        <div
                          class="form-group col-sm-3"
                          v-if="contas_adicionais[index].condicao_pagamento > 0"
                        >
                          <label
                            v-if="
                              contas_adicionais[index].condicao_pagamento == 2
                            "
                            >Data da 1ª parcela:
                            <span class="text-danger">*</span></label
                          >
                          <label
                            v-if="
                              contas_adicionais[index].condicao_pagamento == 1
                            "
                            >Data de vencimento:
                            <span class="text-danger">*</span></label
                          >
                          <input
                            type="date"
                            class="form-control"
                            placeholder="Data de vencimento"
                            :name="
                              'contas_adicionais[' +
                              index +
                              '].[data_vencimento]'
                            "
                            :id="'contas_adicionais[' + index + ']'"
                            v-model="contas_adicionais[index].data_vencimento"
                            @blur="parcelamentoConta(index)"
                          />
                        </div>
                      </div>

                      <div
                        class="form-row"
                        v-if="contas_adicionais[index].parcelas.length > 0"
                      >
                        <div class="col-lg-12">
                          <div class="card">
                            <div class="card-body">
                              <h4 class="card-title text-center">Parcelas</h4>
                              <div class="table-responsive">
                                <table class="table table-striped mb-0">
                                  <thead>
                                    <tr>
                                      <th>Parcela</th>
                                      <th>Data de vencimento</th>
                                      <th>Valor</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(
                                        parcela, index1
                                      ) in conta.parcelasGeradas"
                                      :key="index1"
                                    >
                                      <th scope="row">{{ index1 + 1 }}</th>
                                      <td>
                                        <input
                                          type="date"
                                          class="form-control"
                                          placeholder="Data da parcela"
                                          :name="
                                            'contas_adicionais[' +
                                            index +
                                            '][parcelasGeradas][' +
                                            index1 +
                                            '][vencimento]'
                                          "
                                          :id="
                                            'parcelasGeradas[' + index1 + ']'
                                          "
                                          v-model="
                                            contas_adicionais[index]
                                              .parcelasGeradas[index1]
                                              .vencimento
                                          "
                                        />
                                      </td>
                                      <td>
                                        <div class="input-group">
                                          <span
                                            class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
                                          >
                                            <span class="input-group-text"
                                              >R$</span
                                            ></span
                                          >
                                          <input
                                            type="number"
                                            step="0.01"
                                            class="form-control"
                                            placeholder="Valor da parcela"
                                            :name="
                                              'contas_adicionais[' +
                                              index +
                                              '][parcelasGeradas][' +
                                              index1 +
                                              '][valor]'
                                            "
                                            :id="
                                              'parcelasGeradas[' + index1 + ']'
                                            "
                                            v-model="
                                              contas_adicionais[index]
                                                .parcelasGeradas[index1].valor
                                            "
                                            @blur="parcelamento2(index1)"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="submit"
          class="btn btn-primary"
          title="Lançar"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>Lançar</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
  name: 'LancarContratoModal',
  props: {
    contratoId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      lancamento: {
        titulo: '',
        valor_contrato: '',
        name: '',
        parcelas_pagamento: '',
        client_id: '',
        observacao: '',
        data_pagamento: null,
        tipo: 1,
        origem: 1,
        num_documento: '',
        nota_fiscal: '',
        data_nota_fiscal: '',
        forma_pagamento_id: '',
        subcategoria_contas_id: '',
        unidade_id: '',
        conta_id: '',
      },
      contrato: null,
      num_replicar: '',
      tipo_replicar: 1,
      formasPagamentos: null,
      subcategorias: null,
      unidades: null,
      parcelas: [],
      contas_adicionais: [],
      contas: null,
      disableButton: false,
    }
  },

  methods: {
    // FORMATAR VALOR TOTAL E CAMPOS DE DINHEIRO
    async formatarValor(valorVar, campo) {
      this.$set(this.lancamento, campo, valorVar.toFixed(2))

      // calculo do valor total
      this.lancamento.valor_total = this.lancamento.valor_contrato
    },

    async parcelamento2(index) {
      const novoValor = parseFloat(this.parcelas[index].valor_parcela)
      this.$set(this.parcelas[index], 'valor_parcela', novoValor.toFixed(2))
      let soma = 0
      for (let i = 0; i <= index; i += 1) {
        const valorParcela = parseFloat(this.parcelas[i].valor_parcela)
        soma += valorParcela
      }
      const difValor = this.lancamento.valor_contrato - soma
      let parcelasRestantes = this.lancamento.parcelas_pagamento - index
      parcelasRestantes -= 1
      const valorParcelaAtualizado = difValor / parcelasRestantes
      for (let i = index + 1; i < this.lancamento.parcelas_pagamento; i += 1) {
        this.$set(
          this.parcelas[i],
          'valor_parcela',
          valorParcelaAtualizado.toFixed(2),
        )
      }
    },

    async dataParcelamento(index) {
      const currentDate = new Date(this.parcelas[index].vencimento)
      currentDate.setDate(currentDate.getDate() + 1)
      for (let i = index; i < this.lancamento.parcelas_pagamento; i += 1) {
        this.parcelas[i].vencimento = moment(currentDate).format('YYYY-MM-DD')
        currentDate.setMonth(currentDate.getMonth() + 1)
      }
    },

    async getContrato() {
      try {
        this.contrato = await this.$store.dispatch(
          'getContratoPreLancamento',
          this.contratoId,
        )
        this.parcelas = await this.$store.dispatch(
          'getParcelasByContratoPreLancamento',
          {
            contratoId: this.contratoId,
          },
        )
        this.contas_adicionais = await this.$store.dispatch(
          'getContasAdicionaisByContratoPreLancamento',
          {
            contratoId: this.contratoId,
          },
        )
        this.lancamento.titulo = this.contrato.titulo
        this.lancamento.valor_contrato = this.contrato.valor_contrato
        this.lancamento.name = this.contrato.name
        this.lancamento.parcelas_pagamento = this.contrato.parcelas_pagamento
        this.lancamento.client_id = this.contrato.client_id
        this.lancamento.observacao = this.contrato.observacao
        this.lancamento.forma_pagamento_id = this.contrato.forma_pagamento_id
        this.$bvModal.show('modalLancarContrato')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async getParcelasContrato() {
      try {
        this.parcelasAux = await this.$store.dispatch(
          'getParcelasPreLancamento',
          this.contratoId,
        )
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.parcelasAux.length; i++) {
          this.parcelas.push({
            tituloParcela: `${this.lancamento.titulo} ${i + 1}/${
              this.parcelas_pagamento
            }`,
            valor: this.parcelasAux[i].valor,
            vencimento: this.parcelasAux[i].vencimento,
            competenciaParcela: this.parcelasAux[i].competencia,
          })
        }
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getUnidades() {
      try {
        this.unidades = await this.$store.dispatch('getUnidades')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getFormasPagamentos() {
      try {
        this.formasPagamentos = await this.$store.dispatch(
          'getFormasPagamentos',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getSubcategorias() {
      try {
        this.subcategorias = await this.$store.dispatch(
          'getSubcategoriasContas',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getContas() {
      try {
        this.contas = await this.$store.dispatch('getContas')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          console.log(this.lancamento)
          response = await this.$store.dispatch('savePreLancamento', {
            id: this.contratoId,
            lancamento: this.lancamento,
            parcelas: this.parcelas,
            contas_adicionais: this.contas_adicionais,
          })
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
          })
          setTimeout(() => {
            window.location.reload()
          })
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    lancamento: {
      titulo: {
        required,
      },
      valor_contrato: {
        required,
      },
      forma_pagamento_id: {
        required,
      },
      subcategoria_contas_id: {
        required,
      },
      conta_id: {
        required,
      },
      unidade_id: {
        required,
      },
    },
  },

  mounted() {
    this.getContas()
    this.getSubcategorias()
    this.getFormasPagamentos()
    this.getUnidades()
    if (this.contratoId) {
      this.getContrato()
    }
  },
}
</script>
