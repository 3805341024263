<template>
  <div>
    <FiltroLancamentos ref="list" />
    <b-modal
      id="modalBaixaLancamentosArray"
      title="Baixar Lançamentos"
      title-class="font-18"
      hide-footer
      no-close-on-backdrop
      @hidden="$emit('closeModal', false)"
    >
      <form @submit.prevent="save">
        <div class="form-row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>Valor Total: </label><br />
              <h6 :class="['d-none d-sm-inline-block', classBasedOnValue]">
                {{ valorTotalFormated }}
              </h6>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label
                  >Forma de Pagamento: <span class="text-danger">*</span></label
                >
                <select
                  class="form-control"
                  :class="{
                    'is-invalid': $v.dadosLancamento.forma_pagamento_id.$error,
                  }"
                  v-model="$v.dadosLancamento.forma_pagamento_id.$model"
                >
                  <option value="">Selecione uma forma de pagamento</option>
                  <option
                    v-for="forma in formasPagamentos"
                    :key="forma.id"
                    :value="forma.id"
                    :title="`${forma.descricao}`"
                  >
                    {{ forma.descricao }}
                  </option>
                </select>
                <span
                  v-if="!$v.dadosLancamento.forma_pagamento_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Nº do documento: </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Número do documento"
                  v-model="dadosLancamento.num_documento"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label
                  >Data do pagamento: <span class="text-danger">*</span></label
                >
                <input
                  type="date"
                  class="form-control"
                  placeholder="Date de pagamento"
                  :class="{
                    'is-invalid': $v.dadosLancamento.data_pagamento.$error,
                  }"
                  v-model="$v.dadosLancamento.data_pagamento.$model"
                />
                <span
                  v-if="!$v.dadosLancamento.data_pagamento.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Conta: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{ 'is-invalid': $v.dadosLancamento.conta_id.$error }"
                  v-model="$v.dadosLancamento.conta_id.$model"
                >
                  <option value="">Selecione uma conta</option>
                  <option
                    v-for="conta in contas"
                    :key="conta.id"
                    :value="conta.id"
                    :title="`${conta.descricao}`"
                  >
                    {{ conta.descricao }}
                  </option>
                </select>
                <span
                  v-if="!$v.dadosLancamento.conta_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Filial: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{
                    'is-invalid': $v.dadosLancamento.unidade_id.$error,
                  }"
                  v-model="$v.dadosLancamento.unidade_id.$model"
                >
                  <option value="">Selecione uma filial</option>
                  <option
                    v-for="unidade in unidades"
                    :key="unidade.id"
                    :value="unidade.id"
                    :title="`${unidade.descricao}`"
                  >
                    {{ unidade.descricao }}
                  </option>
                </select>
                <span
                  v-if="!$v.dadosLancamento.unidade_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
              <div class="form-group col-sm-6">
                <label>Subcategoria: <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  :class="{
                    'is-invalid': $v.dadosLancamento.subcategoria_id.$error,
                  }"
                  v-model="$v.dadosLancamento.subcategoria_id.$model"
                >
                  <option value="">Selecione uma subcategoria</option>
                  <option
                    v-for="subcategoria in subcategorias"
                    :key="subcategoria.id"
                    :value="subcategoria.id"
                    :title="`${subcategoria.descricao}`"
                  >
                    {{ subcategoria.descricao }}
                  </option>
                </select>
                <span
                  v-if="!$v.dadosLancamento.subcategoria_id.required"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Nota Fiscal: </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Número do documento"
                  v-model="dadosLancamento.nota_fiscal"
                />
              </div>
              <div class="form-group col-sm-6">
                <label>Data de emissão da NF: </label>
                <input
                  type="date"
                  class="form-control"
                  placeholder="Data de emissão"
                  v-model="dadosLancamento.data_nota_fiscal"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="modal-footer-custom">
          <button
            type="button"
            class="btn btn-secondary"
            title="Cancelar"
            @click="$emit('closeModal', false)"
          >
            Cancelar
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            title="Baixar Lançamentos"
            :disabled="$v.$invalid || disableButton"
          >
            <i
              v-if="disableButton"
              class="spinner-grow spinner-grow-custom text-wither"
            />
            <span v-else>Baixar lançamentos</span>
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { moneyToCurrencyBr } from '@/utils/strings'
import FiltroLancamentos from '@/components/financeiro/lancamentos/FiltroLancamentos.vue'

export default {
  name: 'BaixaPagamentosArrayModal',

  props: {
    lancamentosSelecionados: {
      type: Array,
      default: null,
    },
    valorTotalSelecionados: {
      type: Number,
      default: null,
    },
  },

  components: {
    FiltroLancamentos,
  },

  data() {
    return {
      dadosLancamento: {
        data_pagamento: '',
        forma_pagamento_id: '',
        num_documento: '',
        data_nota_fiscal: '',
        conta_id: '',
        unidade_id: '',
        subcategoria_id: '',
        nota_fiscal: null,
      },
      contas: null,
      fornecedores: null,
      users: null,
      unidades: null,
      formasPagamentos: null,
      subcategorias: null,
      disableButton: false,
    }
  },

  computed: {
    valorTotalFormated() {
      return moneyToCurrencyBr(this.valorTotalSelecionados)
    },
    classBasedOnValue() {
      return this.valorTotalSelecionados > 0 ? 'text-success' : 'text-danger'
    },
  },

  methods: {
    async getUnidades() {
      try {
        this.unidades = await this.$store.dispatch('getUnidades')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getFormasPagamentos() {
      try {
        this.formasPagamentos = await this.$store.dispatch(
          'getFormasPagamentos',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getSubcategorias() {
      try {
        this.subcategorias = await this.$store.dispatch(
          'getSubcategoriasContas',
        )
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getContas() {
      try {
        this.contas = await this.$store.dispatch('getContas')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          response = await this.$store.dispatch('baixarPagamentosArray', {
            dadosLancamento: this.dadosLancamento,
            lancamentosSelecionados: this.lancamentosSelecionados,
          })
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    dadosLancamento: {
      data_pagamento: {
        required,
      },
      forma_pagamento_id: {
        required,
      },
      conta_id: {
        required,
      },
      unidade_id: {
        required,
      },
      subcategoria_id: {
        required,
      },
    },
  },

  async mounted() {
    this.getContas()
    this.getUnidades()
    this.getFormasPagamentos()
    this.getSubcategorias()
    this.$bvModal.show('modalBaixaLancamentosArray')
  },
}
</script>
